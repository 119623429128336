import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import Web3 from "web3";


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100px;
  @media (min-width: 767px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogo2 = styled.img`
  width: 250px;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const Roadmap = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  width: 300px;
  @media (min-width: 900px) {
    width: 500px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const Banner = styled.img`
  text-align: center;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  width: 500px;
  height: auto;

  transition: width 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.ETH_COST;
    let totalCostEther = String(cost * mintAmount);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostEther);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: Web3.utils.toWei(totalCostEther, "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have claimed ${CONFIG.NFT_NAME}!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 8) {
      newMintAmount = 8;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >

        <div className="social-container">

          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/flaremingos">
            <FaTwitter color="pink" size={50} />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/pwpxbrqDky">
            <FaDiscord color="pink" size={50} />
          </a>

        </div>
        <a target="_blank" rel="noopener noreferrer" href="https://flaremingos.app">
          <StyledLogo2 alt={"logo"} src={"/config/images/banner.png"} />
        </a>

        <StyledLogo alt={"pointer"} src={"/config/images/pointer.gif"} />

        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 30,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          🐊The DeleGators🐊
        </s.TextTitle>

        <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}

        >
          The Delegators Mint Is Now Open!
        </s.TextDescription>

        <div style={{ width: 500, textAlign: "center", color: "var(--accent-text)" }}>
          <p>
            Once all DeleGators are minted, each NFT will hold an equal share in the GatorDen.
            The GatorDen gives NFTs FTSO Delegation ability and starts with a pool of 1M FLR that compounds and rewards the DeleGators NFTs through FTSO Delegations, Flare Airdrops and more.
          </p>
        </div>

        <s.SpacerSmall />


        <StyledButton
          style={{ backgroundColor: "green" }}
          onClick={() => window.open("https://mint.flaremingos.app", "_blank")}
        >
          MINT NOW!
        </StyledButton>



        <s.SpacerSmall />

        <a href="https://mint.flaremingos.app">
          <Roadmap alt="delegator banner" src="/config/images/dbanner.png" />
        </a>

        <s.SpacerLarge />

        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 30,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          🦩The Flaremingos🦩
        </s.TextTitle>

        <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}

        >
          The OG MINGOS!
        </s.TextDescription>

        <StyledLink target={"_blank"} href={"https://sparklesnft.com/collection/flare/flaremingos/"}>
          FIND THEM ON Sparkles✨ NFT MARKETPLACE
        </StyledLink>

        <s.SpacerSmall />

        <StyledLink target={"_blank"} href={"https://marketplace.flareocean.io/collection/14/0xe2432f1e376482ec914ebbb910d3bfd8e3f3f29e"}>
          FIND THEM ON FlareOcean🌊 NFT MARKETPLACE
        </StyledLink>

        <s.SpacerSmall />

        <Roadmap alt={"roadmap"} src={"/config/images/mingobanner.png"} />

        <s.SpacerSmall />

        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 30,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          🦩The Flaremingo Frens🦩
        </s.TextTitle>

        <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}

        >
          The close friends of the MINGOS
        </s.TextDescription>

        <StyledLink target={"_blank"} href={"https://sparklesnft.com/collection/flare/frens/"}>
          FIND THEM ON Sparkles✨ NFT MARKETPLACE
        </StyledLink>

        <s.SpacerSmall />
        <StyledLink target={"_blank"} href={"https://marketplace.flareocean.io/collection/14/0x595fa9effad5c0c214b00b1e3004302519bfc1db"}>
          FIND THEM ON FlareOcean🌊 NFT MARKETPLACE
        </StyledLink>
        <s.SpacerSmall />

        <Roadmap alt={"roadmap"} src={"/config/images/frenbanner.png"} />


        <s.SpacerSmall />

        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 30,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          🌊Flare Ocean🌊
        </s.TextTitle>

        <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}

        >
          Support the Flaremingo Ecosystem by delegating to our tech partner, FlareOcean
        </s.TextDescription>


        <s.Container flex={1} jc="center" ai="center">
          <a href="https://metrics.flareocean.io/">
            <img
              alt="frens"
              src="/config/images/focean.png"
              style={{
                width: "300px", // Set the desired width
                height: "auto", // Let the height adjust proportionally
              }}
            />
          </a>
        </s.Container>

        <StyledLink target={"_blank"} href={"https://metrics.flareocean.io/"}>
          Delegate To Flare Ocean!
        </StyledLink>


        <s.SpacerSmall />



        {/* <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}

        >

          Flaremingos, Flare Apes, Gokubi COAI, and Lucky Claw NFT holders added via snapshot. Other participants added via claim form in Flaremingos Discord.      </s.TextDescription> */}

        {/* <a href="https://whitelist.flaremingos.app">
          <StyledButton
            target={"_blank"} href={"https://whitelist.flaremingos.app"}
          >
            GO TO WHITELIST MINT
          </StyledButton>
        </a> */}

        <s.SpacerSmall />

        {/* Minter Box */}
        {/* <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/frens.png"} />
          </s.Container>

          <s.SpacerLarge />

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "MIGRATING" : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/frens.png"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper> */}

        {/* Minter Box */}

        <s.SpacerMedium />

        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container> */}
      </s.Container>
    </s.Screen >
  );
}


export default App;